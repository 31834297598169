import { Inter } from "@next/font/google";
import { appWithTranslation, useTranslation } from "next-i18next";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import "../../../assets/web/styles/global.css";
import { AssetUrls } from "../constants/asset-urls";
import "../styles/app.css";

const CookiesWrapper = dynamic(
  () => import("../components/cookies").then((m) => m.CookiesWrapper),
  { ssr: false }
);

const inter = Inter({
  subsets: ["latin"],
  display: "auto",
  variable: "--font-inter",
});

function CustomApp({ Component, pageProps }: AppProps) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head>
        <title>BeAgro - zarządzanie i optymalizacja badań gleby</title>
        <link rel="icon" href={AssetUrls.favicon} />
        <link rel="manifest" href="/manifest.json" />

        <meta name="description" content={t("landingPage.meta.description")} />

        <meta name="theme-color" content="#335014" />
        <meta name="background" content="#335014" />
      </Head>
      <main className={inter.className}>
        <Component {...pageProps} />
        <CookiesWrapper />
      </main>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default appWithTranslation(CustomApp);
