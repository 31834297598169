export const AssetUrls = {
  back: "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/back.png",
  googlePlay:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/google-play.png",
  mobileApp:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/mobile-app.png",
  printer:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/printer.png",
  tester:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/tester.png",
  uk: "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/uk.png",
  pl: "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/pl.png",
  en: "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/en.png",
  favicon:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/favicon.svg",
  benefits:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/benefits.png",
  appStore:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/app-store.png",
  aboutUs:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/about-us.png",
  technology:
    "https://beagro-s3.fra1.cdn.digitaloceanspaces.com/web-landing/assets/technology.png",
} as const;
